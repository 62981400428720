<template>
  <router-view />
</template>

<script>
// import { computed, provide } from 'vue'
// import { useRoute } from 'vue-router'

export default {
  setup() {
    // const route = useRoute()


    // return {}
  }
}
</script>